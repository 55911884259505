import React from "react";
import { Link } from "gatsby";
import { Layout } from "StaticPages/Layouts";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { Box, Hidden, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useWindowSize } from "mui-extensions/src/Hooks/useWindowSize";
import { TransitionStaticImage } from "StaticPages/Components/TransitionImage";
import { SkewAnimation } from "StaticPages/Components/Animations/SkewAnimation";
import { SectionTitle2 } from "StaticPages/Components/Commons/SectionTitle2";
import { SectionText1 } from "StaticPages/Components/Commons/SectionText1";
import { PageSummary } from "StaticPages/Components/Commons/PageSummary";

export default () => {
    const { t } = useTranslation();
    return <>
        <Helmet title={t("AI - 始まりの物語 | VISUALIZ inc")} />
        <Layout>
            <div>
                <Main />
            </div>
        </Layout>
    </>;
};

const Main = () => {
    const [t] = useTranslation();
    const theme = useTheme();
    return (
        <main>
            <PageSummary
                title={t("始まりの物語")}
                description={<>
                    {t("企業に至る今に続く物語。")}<br />
                    {t("Buddysという個人事務所からVISUALIZへ向かう時期様々な人と出会い、")}
                    {t("企業に至る今に続く物語。")}
                </>}
                subTitle={t("Start up Story")}
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                src={require("../../../static/images/Saas_top.jpg").default}
            />

            <Box
                sx={{
                    background: theme.palette.background.default,
                }}
                p={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionTitle2
                    title={t("2008年～")}
                />
                <Box
                    my={{ xs: 3, sm: 4, md: 6 }}
                >
                    <SectionText1>
                        {t("リーマンショックが世界を駆け巡った2008年12月。")}
                    </SectionText1>
                    <SectionText1>
                        {t("人材派遣会社の中で、ITに関連した事業を独立遊軍のような形で運営していた僕たちに年度内での事業停止とそれに伴う事務所からの撤退が告げられた。")}
                    </SectionText1>
                    <SectionText1>
                        {t("僕たちはAIに付随するあらゆる技術を柔軟に取り入れながら、イノベーションを起こしていきたい。")}
                    </SectionText1>
                    <SectionText1>
                        {t("折しもインターネットバブルが弾け、世の中はもう一度モノづくりを声高に叫ぶ人々とそれでもネットへの可能性に賭ける人々との間に奇妙な分断が生まれつつあった。")}
                    </SectionText1>

                    <SectionText1>
                        {t("成功者と持ち上げられた起業家が何かとの戦いの末、一敗地に塗れ、社会的な抹殺まで行われようとしていた。")}
                    </SectionText1>
                    <SectionText1>
                        {t("それはまるで1984のような、奇妙でどこか歪んだ構図のようにそれでもどこか人ごと（実際そうなのだが）のように見えていた。")}
                    </SectionText1>
                    <SectionText1>
                        {t("今後の事を話し合う同じ事業部の仲間のうち、3人がこの事業を継続していく事になった。")}
                    </SectionText1>
                    <SectionText1>
                        {t("もちろん意地もあったろう。 お世話になった方々への恩返しも頭にあった。")}
                    </SectionText1>
                    <SectionText1>
                        {t("何よりも世界を変える可能性のある、このコロニーから出ていく選択肢は僕にはなかった。")}
                    </SectionText1>
                    <SectionText1>
                        {t("アマゾンはまだ販売以外のサービスはなく、アップルは復帰したジョブズが反撃の狼煙を上げたばかり、Googleとマイクロソフトは巨大な二匹の竜のように戦っている。")}
                    </SectionText1>
                    <SectionText1>
                        {t("オラクルはサンマイクロシステムスとの戦いに血道を上げている。")}
                    </SectionText1>
                    <SectionText1>
                        {t("隔世の感を感じるがそれはまだ10年前、今も続くゼロサムゲームの始まりだった。")}
                    </SectionText1>
                </Box>
            </Box>

            <Box
                p={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionTitle2
                    title={t("個人事務所スタート")}
                />
                <Box
                    my={{ xs: 3, sm: 4, md: 6 }}
                >
                    <SectionText1>
                        {t("今となっては「夢」と言えるのかどうかさえ定かではない。")}
                    </SectionText1>
                    <SectionText1>
                        {t("それでも巷で繰り広げられるWebを包む熱狂に僕たちは逆らえないでいた。")}
                    </SectionText1>
                    <SectionText1>
                        {t("自信などない。")}
                    </SectionText1>
                    <SectionText1>
                        {t("競争の大海原はしがらみや声の大きさは関係なく、ただ純粋な競争が出来る場所に見えた。")}
                    </SectionText1>
                    <SectionText1>
                        {t("それがどれだけ険しく高いものだと知らず、どれだけの大きさかも知らず、才能だけが唯一の武器である熱狂の賭場に一も二もなく飛び込んだ。")}
                    </SectionText1>
                    <SectionText1>
                        {t("そして仲間と始めた事務所にBuddys Creative Office と名付けた。")}
                    </SectionText1>
                    <SectionText1>
                        {t("クライアントに寄り添い創造し続ける、そう思いを込めた。")}
                    </SectionText1>
                    <SectionText1>
                        {t("主な業務はWeb制作と付随するサーバーの管理、そしてGoogleアドワーズを中心とした広告管理。")}
                    </SectionText1>
                    <SectionText1>
                        {t("主な業務はWeb制作と付随するサーバーの管理、そしてGoogleアドワーズを中心とした広告管理。")}
                    </SectionText1>

                    <SectionText1>
                        {t("コーディングの担当者は様々なプログラムを勉強しながら新しい技術を取得する必要があり、僕も様々な文献から業務に役立つ新しい情報を探していた。")}
                    </SectionText1>
                    <SectionText1>
                        {t("独立から3年が過ぎた頃、偶然のツテから東京の仕事を受けるようになった。")}
                    </SectionText1>
                    <SectionText1>
                        {t("新宿駅構内に流れるデジタルサイネージの仕事は、実際に手掛けたかった映像の仕事でもあった。")}
                    </SectionText1>

                    <SectionText1>
                        {t("コーディングと映像、この二つを手掛け始めた頃、僕はその後の仕事を一変させる事となる、ある本と出会った。")}
                    </SectionText1>
                    <SectionText1>
                        {t("その本の名をWIREDと言う。")}
                    </SectionText1>
                    <SectionText1>
                        {t("そこからmakersやオライリーといった様々な図書と出会うのだが、とにかくwired。")}
                    </SectionText1>
                    <SectionText1>
                        {t("思想・デザイン・テクノロジーが混然一体となり、読むにつれ自らのクリエイティブが進んで行く。")}
                    </SectionText1>
                    <SectionText1>
                        {t("同時に僕にはこれからの進むべき道がはっきりと見えた。")}
                    </SectionText1>
                    <SectionText1>
                        {t("デジハリとプロジェクションマッピング 協会が主催したワークショップだったと記憶している。")}
                    </SectionText1>
                    <SectionText1>
                        {t("VVVVと言う聞きなれない開発環境を駆使して作られたその映像は幾何学的な映像が音の強弱やリズムに合わせダンスをしている。それも2Dではなく3Dで。作ったのは AntiVJ のアーティスト、ジョアン。")}
                    </SectionText1>
                    <SectionText1>
                        {t("その当時、リアルタイムの3DデータはOpenGLを使いCでガリガリ書くしかないのでは、と思っていた程度の知識で臨んだ僕には、大きな衝撃だった。")}
                    </SectionText1>

                    <SectionText1>
                        {t("同時に学生が英語で普通に質問をしながら、見るものにさほど驚きもせずノートを取っていたことにも衝撃を覚えた。この中にいる彼らはやがて社会に出て、クリエイティブの世界に入ってくる。")}
                    </SectionText1>
                    <SectionText1>
                        {t("未だ地方の狭い了見で物事を捉えていた僕には、大きな危機感としても心に残った。")}
                    </SectionText1>
                    <SectionText1>
                        {t("とにかく3D映像から高解像度の映像をひたすらレンダリングし、さらにエンコードを掛けMovに落としようやくマッピングソフトウェアの御目に適うデータを作っていた頃の話でもあり、修正が出れば再度3Dから作り直し。")}
                    </SectionText1>
                    <SectionText1>
                        {t("そんな無駄が一切なく、歯切れのいいサウンドに合わせ心地よく動いているプログラムに心を奪われた。")}
                    </SectionText1>
                    <SectionText1>
                        {t("そこからの僕はMakersムーブメントに始まり、フィジカルコンピューティング、Kinnectを使った仕掛けやチーム○ボカメラもどきを開発したり、リアルタイムレンダリングを駆使できるUnrealEngineをマッピングソフトに結合し、様々な表現を可能にする仕組みづくりに熱をあげた。")}
                    </SectionText1>
                    <SectionText1>
                        {t("お陰で2014年が終わる頃には、幾つかのマッピングプロジェクトに関わることが出来るようになっていた。そのプロジェクションマッピングが僕たちの事務所にとって、その後の業態に大きな影響を与える事など思いもせず、ただ目の前のことを必死にこなすだけだった。")}
                    </SectionText1>
                </Box>
            </Box>
        </main >
    );
};
